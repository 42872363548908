import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'

class SendMessage extends React.Component {
  render() {
    const { location, data } = this.props
    const profile = get(data, 'profile.childImageSharp.fixed')
    const work1 = get(data, 'work1.childImageSharp.sizes')
    const work2 = get(data, 'work2.childImageSharp.sizes')
    const back1 = get(data, 'back1.childImageSharp.sizes')
    const back2 = get(data, 'back2.childImageSharp.sizes')

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Send a Message" />

        <div>
          <section className="jumbotron text-left">
            <div className="container">
              <h1 className="jumbotron-heading">Send a Message</h1>
              <p className="lead text-muted">&nbsp;</p>
            </div>
          </section>

          <section className="extra-small">
            <div className="container marketing">
              <div className="row featurette">
                <div className="col-md-12">
                  <form
                    method="post"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                  >
                    <div className="form-group">
                      <label htmlFor="nameInput"></label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="nameInput"
                        aria-describedby="name"
                        placeholder="Your name"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="emailInput"></label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="emailInput"
                        aria-describedby="email"
                        placeholder="Your email"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="messageInput"></label>
                      <textarea
                        className="form-control form-control-lg"
                        id="messageInput"
                        rows="3"
                        aria-describedby="message"
                        placeholder="Your message"
                      ></textarea>
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Send
                    </button>

                    <div className="text-muted">
                      <div className="">
                        <div className="row">
                          <div className="col-md-12">
                            <br />
                            <dl>
                              <dt>Note</dt>
                              <dt>&nbsp;</dt>

                              <dd>
                                Please provide a valid e-mail in order to get
                                confirmation of your submission.
                              </dd>

                              <dd>
                                Be advised that although all e-mails are read
                                not all will receive a response.
                              </dd>

                              <dd>
                                Your e-mail is important to us, but may be
                                better considered by another official and
                                therefore may possible be passed on for their
                                consideration.
                              </dd>

                              <dd>
                                E-mails containing offensive or inappropriate
                                language will not be considered.
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default SendMessage

export const query = graphql`
  query SendMessagePageQuery {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    work1: file(name: { eq: "work1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work2: file(name: { eq: "work2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work3: file(name: { eq: "work3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back1: file(name: { eq: "back1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back2: file(name: { eq: "back2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
